<template>
    <div>
        <div class="contianer-fluid">
            <div class="row">
                <div class="col-md-8">
                    <p @click="backBtn()" class="back-text"><span><i class="fa fa-chevron-left"></i></span>Back</p>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PropertyDetails",
    // props: {
    //     emptyData: {
    //         type: Object,
    //         required: true,
    //     }
    // },
    data() {
        return {
            loading: false,
            activeTab: 1,
        }
    },
    methods: {
        moveToPropertyDetails() {
            this.$router.push({path: `/user/partner/property-details`});
        }
    },
}
</script>

<style scoped lang="scss">

.back-text {
   font-family: Lato;
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   color: #535862;
   margin-bottom: 20px !important;
   cursor: pointer;
   span {
      padding-right: 10px;
   }
}
</style>